var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebarContainer" }, [
    _c(
      "a",
      {
        staticClass: "sidebarOpenBtn",
        on: {
          click: function ($event) {
            _vm.isSidebarOpen = true
          },
        },
      },
      [
        _c("icon", { attrs: { name: "settings" } }),
        _c("p", [_vm._v("Customize page")]),
      ],
      1
    ),
    _vm.isSidebarOpen
      ? _c(
          "div",
          { staticClass: "sidebar" },
          [
            _c("ValidationObserver", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "sidebarCloseBtn",
                            on: {
                              click: function ($event) {
                                _vm.isSidebarOpen = false
                              },
                            },
                          },
                          [_c("icon", { attrs: { name: "close" } })],
                          1
                        ),
                        _c("div", { staticClass: "sidebar__section" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "sidebar__title",
                              on: {
                                click: function ($event) {
                                  return _vm.openCloseSection("colors")
                                },
                              },
                            },
                            [_vm._v("Colors")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sidebar__content",
                              class: {
                                "sidebar__content--open":
                                  _vm.openSection === "colors",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("PColorPicker", {
                                    attrs: {
                                      colorKey: "color_body_background",
                                      displayText: "Body Background color",
                                      parentState:
                                        _vm.ColorPickerParentStateType
                                          .MailingListPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("PColorPicker", {
                                    attrs: {
                                      colorKey: "color_body_text",
                                      displayText: "Body Text color",
                                      parentState:
                                        _vm.ColorPickerParentStateType
                                          .MailingListPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("PColorPicker", {
                                    attrs: {
                                      colorKey: "color_primary_button_bg",
                                      displayText:
                                        "Primary button background color",
                                      parentState:
                                        _vm.ColorPickerParentStateType
                                          .MailingListPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("PColorPicker", {
                                    attrs: {
                                      colorKey: "color_primary_button_text",
                                      displayText: "Primary button text color",
                                      parentState:
                                        _vm.ColorPickerParentStateType
                                          .MailingListPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("PColorPicker", {
                                    attrs: {
                                      colorKey: "color_input_text",
                                      displayText: "Inputs text color",
                                      parentState:
                                        _vm.ColorPickerParentStateType
                                          .MailingListPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("PColorPicker", {
                                    attrs: {
                                      colorKey: "color_input_background",
                                      displayText: "Inputs background color",
                                      parentState:
                                        _vm.ColorPickerParentStateType
                                          .MailingListPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "sidebar__section" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "sidebar__title",
                              on: {
                                click: function ($event) {
                                  return _vm.openCloseSection("header")
                                },
                              },
                            },
                            [_vm._v("Header")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sidebar__content",
                              class: {
                                "sidebar__content--open":
                                  _vm.openSection === "header",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("PColorPicker", {
                                    attrs: {
                                      colorKey: "header_bg_color",
                                      displayText: "Background color",
                                      parentState:
                                        _vm.ColorPickerParentStateType
                                          .MailingListPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Dim Background Image")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.mailingListPage.header_dim_amount,
                                      expression:
                                        "mailingListPage.header_dim_amount",
                                    },
                                  ],
                                  staticClass: "formInput__slider",
                                  attrs: {
                                    type: "range",
                                    min: "0",
                                    max: "100",
                                  },
                                  domProps: {
                                    value:
                                      _vm.mailingListPage.header_dim_amount,
                                  },
                                  on: {
                                    __r: function ($event) {
                                      return _vm.$set(
                                        _vm.mailingListPage,
                                        "header_dim_amount",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Background Image")]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "formUpload",
                                    class: {
                                      "formUpload--empty":
                                        !_vm.mailingListPage.header_image_url,
                                      "formUpload--uploaded":
                                        _vm.mailingListPage.header_image_url,
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      staticClass: "formUpload__icon",
                                      attrs: { name: "camera" },
                                    }),
                                    _vm.mailingListPage.header_image_url
                                      ? _c("img", {
                                          staticClass: "formUpload__image",
                                          attrs: {
                                            src: _vm.mailingListPage
                                              .header_image_url,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("input", {
                                      staticClass: "formUpload__inputHide",
                                      attrs: { type: "file" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.uploadFile(
                                            $event.target.files,
                                            _vm.FileToRemoveOrAdd
                                              .HeaderBackground
                                          )
                                        },
                                      },
                                    }),
                                    _vm.mailingListPage.header_image_url
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn--danger btn--sm formUpload__btnDelete",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(
                                                  "header_image_url"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Remove")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "formLabel",
                                    staticStyle: { "white-space": "normal" },
                                  },
                                  [
                                    _vm._v(
                                      "Recommended: 2000px x 500px (.jpg or .png)"
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "sidebar__item sidebar__item--big",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mailingListPage.header_text,
                                        expression:
                                          "mailingListPage.header_text",
                                      },
                                    ],
                                    staticClass:
                                      "formInput formInput--fullWidth",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Header Text",
                                    },
                                    domProps: {
                                      value: _vm.mailingListPage.header_text,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mailingListPage,
                                          "header_text",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "sidebar__section" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "sidebar__title",
                              on: {
                                click: function ($event) {
                                  return _vm.openCloseSection("textContent1")
                                },
                              },
                            },
                            [_vm._v("Text Content 1")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sidebar__content",
                              class: {
                                "sidebar__content--open":
                                  _vm.openSection === "textContent1",
                              },
                            },
                            [
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Title")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.mailingListPage.text_title,
                                      expression: "mailingListPage.text_title",
                                    },
                                  ],
                                  staticClass: "formInput formInput--fullWidth",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Main Title",
                                  },
                                  domProps: {
                                    value: _vm.mailingListPage.text_title,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.mailingListPage,
                                        "text_title",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("First Paragraph")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.mailingListPage.text_content1,
                                      expression:
                                        "mailingListPage.text_content1",
                                    },
                                  ],
                                  staticClass: "formInput formInput--fullWidth",
                                  attrs: {
                                    rows: "5",
                                    type: "text",
                                    placeholder: "Paragraph",
                                  },
                                  domProps: {
                                    value: _vm.mailingListPage.text_content1,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.mailingListPage,
                                        "text_content1",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "sidebar__section" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "sidebar__title",
                              on: {
                                click: function ($event) {
                                  return _vm.openCloseSection("youtube")
                                },
                              },
                            },
                            [_vm._v("Video")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sidebar__content",
                              class: {
                                "sidebar__content--open":
                                  _vm.openSection === "youtube",
                              },
                            },
                            [
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.youtubeVideoLink,
                                      expression: "youtubeVideoLink",
                                    },
                                  ],
                                  staticClass: "formInput formInput--fullWidth",
                                  attrs: {
                                    type: "text",
                                    placeholder: "YouTube Video Link",
                                  },
                                  domProps: { value: _vm.youtubeVideoLink },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.youtubeVideoLink = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "sidebar__section" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "sidebar__title",
                              on: {
                                click: function ($event) {
                                  return _vm.openCloseSection("textContent2")
                                },
                              },
                            },
                            [_vm._v("Text Content 2")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sidebar__content",
                              class: {
                                "sidebar__content--open":
                                  _vm.openSection === "textContent2",
                              },
                            },
                            [
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Second Paragraph")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.mailingListPage.text_content2,
                                      expression:
                                        "mailingListPage.text_content2",
                                    },
                                  ],
                                  staticClass: "formInput formInput--fullWidth",
                                  attrs: {
                                    rows: "5",
                                    type: "text",
                                    placeholder: "Paragraph",
                                  },
                                  domProps: {
                                    value: _vm.mailingListPage.text_content2,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.mailingListPage,
                                        "text_content2",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "sidebar__section" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "sidebar__title",
                              on: {
                                click: function ($event) {
                                  return _vm.openCloseSection("thankYou")
                                },
                              },
                            },
                            [_vm._v("Thank You Page")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sidebar__content",
                              class: {
                                "sidebar__content--open sidebar__content--open--xl":
                                  _vm.openSection === "thankYou",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "btn btn--grey btn--border btn--sm",
                                      attrs: {
                                        to: { name: "MailingListThankYou" },
                                      },
                                    },
                                    [_vm._v("Visit page")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Image")]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "formUpload",
                                    class: {
                                      "formUpload--empty":
                                        !_vm.mailingListPage.thankyou_image_url,
                                      "formUpload--uploaded":
                                        _vm.mailingListPage.thankyou_image_url,
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      staticClass: "formUpload__icon",
                                      attrs: { name: "camera" },
                                    }),
                                    _vm.mailingListPage.thankyou_image_url
                                      ? _c("img", {
                                          staticClass: "formUpload__image",
                                          attrs: {
                                            src: _vm.mailingListPage
                                              .thankyou_image_url,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("input", {
                                      staticClass: "formUpload__inputHide",
                                      attrs: { type: "file" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.uploadFile(
                                            $event.target.files,
                                            _vm.FileToRemoveOrAdd
                                              .HeaderBackground
                                          )
                                        },
                                      },
                                    }),
                                    _vm.mailingListPage.thankyou_image_url
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn--danger btn--sm formUpload__btnDelete",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(
                                                  "thankyou_image_url"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Remove")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "formLabel",
                                    staticStyle: { "white-space": "normal" },
                                  },
                                  [
                                    _vm._v(
                                      "Recommended: 300px x 300px (.jpg or .png) "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Title")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.mailingListPage.thankyou_text_title,
                                      expression:
                                        "mailingListPage.thankyou_text_title",
                                    },
                                  ],
                                  staticClass: "formInput formInput--fullWidth",
                                  attrs: { type: "text", placeholder: "Title" },
                                  domProps: {
                                    value:
                                      _vm.mailingListPage.thankyou_text_title,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.mailingListPage,
                                        "thankyou_text_title",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Description")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.mailingListPage
                                          .thankyou_text_content,
                                      expression:
                                        "mailingListPage.thankyou_text_content",
                                    },
                                  ],
                                  staticClass: "formInput formInput--fullWidth",
                                  attrs: {
                                    rows: "5",
                                    type: "text",
                                    placeholder: "Paragraph",
                                  },
                                  domProps: {
                                    value:
                                      _vm.mailingListPage.thankyou_text_content,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.mailingListPage,
                                        "thankyou_text_content",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "sidebar__item" }, [
                                _c("p", [_vm._v("Button Text")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.mailingListPage.thankyou_btn_text,
                                      expression:
                                        "mailingListPage.thankyou_btn_text",
                                    },
                                  ],
                                  staticClass: "formInput formInput--fullWidth",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Enter Button Text",
                                  },
                                  domProps: {
                                    value:
                                      _vm.mailingListPage.thankyou_btn_text,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.mailingListPage,
                                        "thankyou_btn_text",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                { staticClass: "sidebar__item" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Button Link",
                                      rules: { regex: _vm.regexUrl },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("p", [_vm._v("Button Link")]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.mailingListPage
                                                        .thankyou_btn_link,
                                                    expression:
                                                      "mailingListPage.thankyou_btn_link",
                                                  },
                                                ],
                                                staticClass:
                                                  "formInput formInput--fullWidth",
                                                class: {
                                                  hasError: errors.length > 0,
                                                },
                                                attrs: {
                                                  name: "button_link",
                                                  placeholder:
                                                    "Enter Button Link",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.mailingListPage
                                                      .thankyou_btn_link,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.mailingListPage,
                                                      "thankyou_btn_link",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "formInput__hint formInput__hint--error",
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flexbox flexbox--center h-mt-40" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--black",
                                on: {
                                  click: function ($event) {
                                    return handleSubmit(_vm.saveChanges)
                                  },
                                },
                              },
                              [_vm._v("Save Changes")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                705138345
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }