var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar",
      style: {
        backgroundColor: _vm.elitepage.navbar_background_color,
        color: _vm.elitepage.navbar_link_color,
      },
    },
    [
      _c(
        "ul",
        {
          staticClass: "navbar--mobile navbar__items",
          style: {
            backgroundColor: _vm.elitepage.navbar_background_color,
            color: _vm.elitepage.navbar_link_color,
          },
        },
        [
          _c(
            "li",
            { staticClass: "navbar__item navbar__logo" },
            [
              _vm.elitepage.navbar_is_text
                ? _c("router-link", { attrs: { to: { name: "Home" } } }, [
                    _vm._v(_vm._s(_vm.elitepage.navbar_brand_name)),
                  ])
                : !_vm.elitepage.navbar_is_text &&
                  _vm.elitepage.navbar_brand_image_url
                ? _c("router-link", { attrs: { to: { name: "Home" } } }, [
                    _c("img", {
                      staticClass: "navbar__logo--image",
                      attrs: { src: _vm.elitepage.navbar_brand_image_url },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass:
                "navbar__item navbar__search navbar__search navbar__item--right",
            },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toggleMobileMenu("search")
                    },
                  },
                },
                [_c("icon", { attrs: { name: "search" } })],
                1
              ),
            ]
          ),
          _c(
            "li",
            { staticClass: "navbar__item" },
            [
              _c(
                "router-link",
                {
                  staticClass: "navbar__cart",
                  attrs: { to: { name: "Cart" } },
                },
                [
                  _c("icon", {
                    staticClass: "navbar__cart-icon--small",
                    attrs: { name: "cart" },
                  }),
                  _c("div", { staticClass: "navbar__cart-count" }, [
                    _c("span", [
                      _vm._v(_vm._s(!_vm.cart ? 0 : _vm.cart.length)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("li", { staticClass: "navbar__item" }, [
            _c(
              "a",
              { on: { click: _vm.toggleMobileMenu } },
              [_c("icon", { attrs: { name: "hamburger" } })],
              1
            ),
          ]),
        ]
      ),
      _vm.showMobileMenu
        ? _c(
            "a",
            {
              staticClass: "navbar__close",
              on: { click: _vm.toggleMobileMenu },
            },
            [_c("icon", { attrs: { name: "close" } })],
            1
          )
        : _vm._e(),
      _c(
        "ul",
        {
          staticClass: "navbar--desktop navbar__items",
          class: { "navbar--desktop--open": _vm.showMobileMenu },
        },
        [
          _c(
            "li",
            { staticClass: "navbar__item navbar__logo" },
            [
              _vm.elitepage.navbar_is_text
                ? _c("router-link", { attrs: { to: { name: "Home" } } }, [
                    _vm._v(_vm._s(_vm.elitepage.navbar_brand_name)),
                  ])
                : !_vm.elitepage.navbar_is_text &&
                  _vm.elitepage.navbar_brand_image_url
                ? _c("router-link", { attrs: { to: { name: "Home" } } }, [
                    _c("img", {
                      staticClass: "navbar__logo--image",
                      attrs: { src: _vm.elitepage.navbar_brand_image_url },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Beats" } } }, [
                _vm._v("Beats"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home", hash: "#licensingInfo" } } },
                [_vm._v("Licensing Info")]
              ),
            ],
            1
          ),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.elitepage.about_visible,
                  expression: "elitepage.about_visible",
                },
              ],
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "About" } } }, [
                _vm._v("About"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "SoundKits" } } }, [
                _vm._v("Sound Kits"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Services" } } }, [
                _vm._v("Services"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home", hash: "#contact" } } },
                [_vm._v("Contact")]
              ),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__search navbar__item--right",
              class: { "navbar__search--hide": !_vm.isSearch },
            },
            [_c("navbar-search", { on: { closeMenu: _vm.toggleMobileMenu } })],
            1
          ),
          _c(
            "li",
            { staticClass: "navbar__item navbar__cart" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Cart" } } },
                [
                  _vm._v("Cart"),
                  _c("icon", {
                    staticClass: "navbar__cart-icon",
                    attrs: { name: "cart" },
                  }),
                  _c("div", { staticClass: "navbar__cart-count" }, [
                    _c("span", [
                      _vm._v(_vm._s(!_vm.cart ? 0 : _vm.cart.length)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isAuthenticated
            ? _c(
                "li",
                {
                  staticClass: "navbar__item navbar__item--btn",
                  class: { "navbar__item--hide": _vm.isSearch },
                  on: { click: _vm.toggleMobileMenu },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn--white btn--text",
                      attrs: {
                        to: {
                          name: "Login",
                          query: { redirect: this.$route.fullPath },
                        },
                      },
                    },
                    [_vm._v("Login")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn--primary",
                      attrs: {
                        to: {
                          name: "Signup",
                          query: { redirect: this.$route.fullPath },
                        },
                      },
                    },
                    [_vm._v("SIGNUP")]
                  ),
                ],
                1
              )
            : _c("navbar-user", {
                class: { "navbar__item--hide": _vm.isSearch },
                on: { closeMenu: _vm.toggleMobileMenu },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }