var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "navbar__item dropdown userDropdown__toggle" },
    [
      _c(
        "a",
        { staticClass: "flexbox h-hide@tablet" },
        [
          _c("icon", { attrs: { name: "profile", width: "30", height: "30" } }),
          _c("icon", {
            staticStyle: { "margin-left": "8px" },
            attrs: { name: "arrow-down", width: "16", height: "16" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "userDropdown__menu" }, [
        _c(
          "div",
          { staticClass: "userDropdown__top" },
          [
            _c("icon", {
              staticClass: "userDropdown__icon",
              attrs: { name: "profile" },
            }),
            _c("div", [
              _c("p", { staticClass: "userDropdown__name" }, [
                _vm._v(
                  _vm._s(
                    _vm.authenticatedUser
                      ? _vm.authenticatedUser.first_name
                      : "name"
                  )
                ),
              ]),
              _c("p", { staticClass: "userDropdown__email" }, [
                _vm._v(
                  _vm._s(
                    _vm.authenticatedUser
                      ? _vm.authenticatedUser.email
                      : "email"
                  )
                ),
              ]),
            ]),
          ],
          1
        ),
        _c("ul", { staticClass: "userDropdown__bottom" }, [
          _c(
            "li",
            { staticClass: "userDropdown__link", on: { click: _vm.closeMenu } },
            [
              _c("a", { attrs: { href: "https://thecharts.com/" } }, [
                _vm._v("TheCharts"),
              ]),
            ]
          ),
          _c(
            "li",
            { staticClass: "userDropdown__link", on: { click: _vm.closeMenu } },
            [_c("a", { on: { click: _vm.logout } }, [_vm._v("Log Out")])]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }