var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass: "footer",
      class: [_vm.playerIsShowing, _vm.dealBarIsShowing],
    },
    [
      _c("div", { staticClass: "footer__main container container--lg" }, [
        _c("div", { staticClass: "footer__logo" }, [
          _vm._v(_vm._s(_vm.elitepage.footer_text)),
        ]),
        _c("ul", { staticClass: "footer__links" }, [
          _c(
            "li",
            { staticClass: "footer__link" },
            [
              _c("router-link", { attrs: { to: { name: "Beats" } } }, [
                _vm._v("Beats"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "footer__link" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home", hash: "#licensingInfo" } } },
                [_vm._v("Licensing Info")]
              ),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "footer__link" },
            [
              _c("router-link", { attrs: { to: { name: "SoundKits" } } }, [
                _vm._v("Sound Kits"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "footer__link" },
            [
              _c("router-link", { attrs: { to: { name: "Services" } } }, [
                _vm._v("Services"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "footer__link" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home", hash: "#contact" } } },
                [_vm._v("Contact")]
              ),
            ],
            1
          ),
        ]),
        _vm.producer
          ? _c("ul", { staticClass: "icons-social" }, [
              _c("li", [
                _vm.producer.facebook
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.producer.facebook,
                          target: "_blank",
                        },
                      },
                      [_c("icon", { attrs: { name: "facebook" } })],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("li", [
                _vm.producer.twitter
                  ? _c(
                      "a",
                      {
                        attrs: { href: _vm.producer.twitter, target: "_blank" },
                      },
                      [_c("icon", { attrs: { name: "twitter" } })],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("li", [
                _vm.producer.instagram
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.producer.instagram,
                          target: "_blank",
                        },
                      },
                      [_c("icon", { attrs: { name: "instagram" } })],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("li", [
                _vm.producer.youtube
                  ? _c(
                      "a",
                      {
                        attrs: { href: _vm.producer.youtube, target: "_blank" },
                      },
                      [_c("icon", { attrs: { name: "youtube" } })],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("li", [
                _vm.producer.soundcloud
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.producer.soundcloud,
                          target: "_blank",
                        },
                      },
                      [_c("icon", { attrs: { name: "soundcloud" } })],
                      1
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "a",
        { staticClass: "creditsTag", attrs: { href: "#" } },
        [
          _c("p", [_vm._v("Powered By ")]),
          _c("icon", {
            staticClass: "creditsTag__logo",
            attrs: { name: "logo", color: "white" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }