var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebarContainer" }, [
    _c(
      "a",
      {
        staticClass: "sidebarOpenBtn",
        on: {
          click: function ($event) {
            _vm.isSidebarOpen = true
          },
        },
      },
      [
        _c("icon", { attrs: { name: "settings" } }),
        _c("p", [_vm._v("Customize Elite page")]),
      ],
      1
    ),
    _vm.isSidebarOpen
      ? _c("div", { staticClass: "sidebar" }, [
          _c(
            "a",
            {
              staticClass: "sidebarCloseBtn",
              on: {
                click: function ($event) {
                  _vm.isSidebarOpen = false
                },
              },
            },
            [_c("icon", { attrs: { name: "close" } })],
            1
          ),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("section_order")
                  },
                },
              },
              [_vm._v("Sections & Order")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "section_order",
                },
              },
              [
                _vm._m(0),
                _c(
                  "draggable",
                  {
                    staticClass: "sidebar__item",
                    model: {
                      value: _vm.bodySections,
                      callback: function ($$v) {
                        _vm.bodySections = $$v
                      },
                      expression: "bodySections",
                    },
                  },
                  _vm._l(_vm.bodySections, function (element) {
                    return _c(
                      "div",
                      { key: element.key, staticClass: "orderItem" },
                      [
                        _c("icon", { attrs: { name: "hamburger" } }),
                        _c("h4", [_vm._v(_vm._s(element.displayName))]),
                        element.key !== "beats" &&
                        element.key !== "licensing_information"
                          ? _c("ToggleButton", {
                              attrs: { color: "#0269FF", labels: true },
                              model: {
                                value: element.visible,
                                callback: function ($$v) {
                                  _vm.$set(element, "visible", $$v)
                                },
                                expression: "element.visible",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("colors")
                  },
                },
              },
              [_vm._v("Colors")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "colors",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_body_background",
                        displayText: "Body Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_body_text",
                        displayText: "Body Text color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_primary_button_bg",
                        displayText: "Primary button background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_primary_button_text",
                        displayText: "Primary button text color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_secondary_button",
                        displayText: "Secondary button color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_input_border",
                        displayText: "Inputs border color",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("about")
                  },
                },
              },
              [_vm._v("About")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "about",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flexbox sidebar__item" },
                  [
                    _c("p", [_vm._v("Show/Hide page")]),
                    _c("ToggleButton", {
                      staticClass: "vue-js-switch--margin",
                      attrs: {
                        value: _vm.elitepage.about_visible,
                        color: "black",
                      },
                      on: { change: _vm.toggleShowHideAbout },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("logo")
                  },
                },
              },
              [_vm._v("Logo")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: { "sidebar__content--open": _vm.openSection === "logo" },
              },
              [
                _c(
                  "div",
                  { staticClass: "flexbox sidebar__item" },
                  [
                    _c("p", [_vm._v("Logo Type")]),
                    _c("ToggleButton", {
                      staticClass: "vue-js-switch--margin",
                      attrs: {
                        value: _vm.elitepage.navbar_is_text,
                        color: "black",
                        labels: { checked: "Text", unchecked: "Image" },
                        width: 64,
                      },
                      on: { change: _vm.toggleLogoType },
                    }),
                  ],
                  1
                ),
                _vm.elitepage.navbar_is_text
                  ? _c("div", { staticClass: "sidebar__item" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.elitepage.navbar_brand_name,
                            expression: "elitepage.navbar_brand_name",
                          },
                        ],
                        staticClass: "formInput formInput--fullWidth",
                        attrs: { type: "text", placeholder: "Logo Text" },
                        domProps: { value: _vm.elitepage.navbar_brand_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.elitepage,
                              "navbar_brand_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _c("div", { staticClass: "sidebar__item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "formUpload",
                          class: {
                            "formUpload--empty":
                              !_vm.elitepage.navbar_brand_image_url,
                            "formUpload--uploaded":
                              _vm.elitepage.navbar_brand_image_url,
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "formUpload__icon",
                            attrs: { name: "camera" },
                          }),
                          _vm.elitepage.navbar_brand_image_url
                            ? _c("img", {
                                staticClass: "formUpload__image",
                                attrs: {
                                  src: _vm.elitepage.navbar_brand_image_url,
                                },
                              })
                            : _vm._e(),
                          _c("input", {
                            staticClass: "formUpload__inputHide",
                            attrs: { type: "file" },
                            on: {
                              change: function ($event) {
                                return _vm.uploadFile(
                                  $event.target.files,
                                  _vm.FileToRemoveOrAdd.Logo
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "formLabel",
                          staticStyle: { "white-space": "normal" },
                        },
                        [_vm._v("Recommended 60px height (.png)")]
                      ),
                    ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("navbar")
                  },
                },
              },
              [_vm._v("Navbar")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "navbar",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "navbar_background_color",
                        displayText: "Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "navbar_link_color",
                        displayText: "Link color",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("header")
                  },
                },
              },
              [_vm._v("Header")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "header",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flexbox sidebar__item h-mb-20" },
                  [
                    _c("p", [_vm._v("Show Pageviews & Plays")]),
                    _c("ToggleButton", {
                      staticClass: "vue-js-switch--margin",
                      attrs: {
                        value: _vm.elitepage.show_analytics,
                        color: "black",
                        labels: true,
                      },
                      on: { change: _vm.toggleShowAnalytics },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "header_bg_color",
                        displayText: "Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Dim Background Image")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.header_dim_amount,
                        expression: "elitepage.header_dim_amount",
                      },
                    ],
                    staticClass: "formInput__slider",
                    attrs: { type: "range", min: "0", max: "100" },
                    domProps: { value: _vm.elitepage.header_dim_amount },
                    on: {
                      __r: function ($event) {
                        return _vm.$set(
                          _vm.elitepage,
                          "header_dim_amount",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Background Image")]),
                  _c(
                    "div",
                    {
                      staticClass: "formUpload",
                      class: {
                        "formUpload--empty": !_vm.elitepage.header_image_url,
                        "formUpload--uploaded": _vm.elitepage.header_image_url,
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "formUpload__icon",
                        attrs: { name: "camera" },
                      }),
                      _vm.elitepage.header_image_url
                        ? _c("img", {
                            staticClass: "formUpload__image",
                            attrs: { src: _vm.elitepage.header_image_url },
                          })
                        : _vm._e(),
                      _c("input", {
                        staticClass: "formUpload__inputHide",
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.uploadFile(
                              $event.target.files,
                              _vm.FileToRemoveOrAdd.HeaderBackground
                            )
                          },
                        },
                      }),
                      _vm.elitepage.header_image_url
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "btn btn--danger btn--sm formUpload__btnDelete",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile("header_image_url")
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "formLabel",
                      staticStyle: { "white-space": "normal" },
                    },
                    [_vm._v("Recommended: 2000px x 500px (.jpg or .png)")]
                  ),
                ]),
                _c("div", { staticClass: "sidebar__item sidebar__item--big" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.header_text,
                        expression: "elitepage.header_text",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { type: "text", placeholder: "Header Text" },
                    domProps: { value: _vm.elitepage.header_text },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.elitepage,
                          "header_text",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("licensing_information")
                  },
                },
              },
              [_vm._v("Licensing Information")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open":
                    _vm.openSection === "licensing_information",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "licensing_info_bg_color",
                        displayText: "Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "licensing_info_text_color",
                        displayText: "Titles color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "licensing_info_table_bg_color",
                        displayText: "Tables color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "licensing_info_table_text_color",
                        displayText: "Tables text color",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("sound_kits")
                  },
                },
              },
              [_vm._v("Sound Kits")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "sound_kits",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "soundkit_bg_color",
                        displayText: "Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "soundkit_text_color",
                        displayText: "Title color",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Dim Background Image")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.soundkit_dim_amount,
                        expression: "elitepage.soundkit_dim_amount",
                      },
                    ],
                    staticClass: "formInput__slider",
                    attrs: { type: "range", min: "0", max: "100" },
                    domProps: { value: _vm.elitepage.soundkit_dim_amount },
                    on: {
                      __r: function ($event) {
                        return _vm.$set(
                          _vm.elitepage,
                          "soundkit_dim_amount",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Background Image")]),
                  _c(
                    "div",
                    {
                      staticClass: "formUpload",
                      class: {
                        "formUpload--empty": !_vm.elitepage.soundkit_image_url,
                        "formUpload--uploaded":
                          _vm.elitepage.soundkit_image_url,
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "formUpload__icon",
                        attrs: { name: "camera" },
                      }),
                      _vm.elitepage.soundkit_image_url
                        ? _c("img", {
                            staticClass: "formUpload__image",
                            attrs: { src: _vm.elitepage.soundkit_image_url },
                          })
                        : _vm._e(),
                      _c("input", {
                        staticClass: "formUpload__inputHide",
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.uploadFile(
                              $event.target.files,
                              _vm.FileToRemoveOrAdd.SoundKitsBackground
                            )
                          },
                        },
                      }),
                      _vm.elitepage.soundkit_image_url
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "btn btn--danger btn--sm formUpload__btnDelete",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile("soundkit_image_url")
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "formLabel",
                      staticStyle: { "white-space": "normal" },
                    },
                    [_vm._v("Recommended 2000px x 600px (.jpg or .png)")]
                  ),
                ]),
                _c("div", { staticClass: "sidebar__item sidebar__item--big" }, [
                  _c("p", [_vm._v("Description in the page")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.soundkit_header_description,
                        expression: "elitepage.soundkit_header_description",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: {
                      type: "text",
                      rows: "3",
                      placeholder:
                        "'Enter a description for the Sound Kits page",
                    },
                    domProps: {
                      value: _vm.elitepage.soundkit_header_description,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.elitepage,
                          "soundkit_header_description",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("services")
                  },
                },
              },
              [_vm._v("Services")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "services",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "service_bg_color",
                        displayText: "Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "service_text_color",
                        displayText: "Title color",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Dim Background Image")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.service_dim_amount,
                        expression: "elitepage.service_dim_amount",
                      },
                    ],
                    staticClass: "formInput__slider",
                    attrs: { type: "range", min: "0", max: "100" },
                    domProps: { value: _vm.elitepage.service_dim_amount },
                    on: {
                      __r: function ($event) {
                        return _vm.$set(
                          _vm.elitepage,
                          "service_dim_amount",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Background Image")]),
                  _c(
                    "div",
                    {
                      staticClass: "formUpload",
                      class: {
                        "formUpload--empty": !_vm.elitepage.service_image_url,
                        "formUpload--uploaded": _vm.elitepage.service_image_url,
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "formUpload__icon",
                        attrs: { name: "camera" },
                      }),
                      _vm.elitepage.service_image_url
                        ? _c("img", {
                            staticClass: "formUpload__image",
                            attrs: { src: _vm.elitepage.service_image_url },
                          })
                        : _vm._e(),
                      _c("input", {
                        staticClass: "formUpload__inputHide",
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.uploadFile(
                              $event.target.files,
                              _vm.FileToRemoveOrAdd.ServicesBackground
                            )
                          },
                        },
                      }),
                      _vm.elitepage.service_image_url
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "btn btn--danger btn--sm formUpload__btnDelete",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile("service_image_url")
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "formLabel",
                      staticStyle: { "white-space": "normal" },
                    },
                    [_vm._v("Recommended 2000px x 600px (.jpg or .png)")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "service_table_bg_color",
                        displayText: "Tables color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "service_table_text_color",
                        displayText: "Tables text color",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebar__item sidebar__item--big" }, [
                  _c("p", [_vm._v("Description in the page")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.service_header_description,
                        expression: "elitepage.service_header_description",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: {
                      type: "text",
                      rows: "3",
                      placeholder:
                        "'Enter a description for the Sound Kits page",
                    },
                    domProps: {
                      value: _vm.elitepage.service_header_description,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.elitepage,
                          "service_header_description",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("youtube")
                  },
                },
              },
              [_vm._v("YouTube")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "youtube",
                },
              },
              [
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.youtubeVideoLink,
                        expression: "youtubeVideoLink",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { type: "text", placeholder: "YouTube Video Link" },
                    domProps: { value: _vm.youtubeVideoLink },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.youtubeVideoLink = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "sidebar__item sidebar__item--big" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "youtube_bg_color",
                        displayText: "Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "youtube_text_color",
                        displayText: "Text color",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Dim Background Image")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.youtube_dim_amount,
                        expression: "elitepage.youtube_dim_amount",
                      },
                    ],
                    staticClass: "formInput__slider",
                    attrs: { type: "range", min: "0", max: "100" },
                    domProps: { value: _vm.elitepage.youtube_dim_amount },
                    on: {
                      __r: function ($event) {
                        return _vm.$set(
                          _vm.elitepage,
                          "youtube_dim_amount",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Background Image")]),
                  _c(
                    "div",
                    {
                      staticClass: "formUpload",
                      class: {
                        "formUpload--empty": !_vm.elitepage.service_image_url,
                        "formUpload--uploaded": _vm.elitepage.youtube_image_url,
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "formUpload__icon",
                        attrs: { name: "camera" },
                      }),
                      _vm.elitepage.youtube_image_url
                        ? _c("img", {
                            staticClass: "formUpload__image",
                            attrs: { src: _vm.elitepage.youtube_image_url },
                          })
                        : _vm._e(),
                      _c("input", {
                        staticClass: "formUpload__inputHide",
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.uploadFile(
                              $event.target.files,
                              _vm.FileToRemoveOrAdd.ServicesBackground
                            )
                          },
                        },
                      }),
                      _vm.elitepage.youtube_image_url
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "btn btn--danger btn--sm formUpload__btnDelete",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile("youtube_image_url")
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "formLabel",
                      staticStyle: { "white-space": "normal" },
                    },
                    [_vm._v("Recommended 2000px x 600px (.jpg or .png)")]
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("footer")
                  },
                },
              },
              [_vm._v("Footer")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "footer",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "footer_bg_color",
                        displayText: "Background color",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "footer_items_color",
                        displayText: "Items color",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebar__item sidebar__item--big" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elitepage.footer_text,
                        expression: "elitepage.footer_text",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { type: "text", placeholder: "Footer Text" },
                    domProps: { value: _vm.elitepage.footer_text },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.elitepage,
                          "footer_text",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "flexbox flexbox--center h-mt-40" }, [
            _c(
              "button",
              { staticClass: "btn btn--black", on: { click: _vm.saveChanges } },
              [_vm._v("Save Changes")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar__item" }, [
      _c("p", [_vm._v("Drag to re-order sections")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }