var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "colorPicker" },
    [
      _c("verte", {
        attrs: {
          model: "hex",
          draggable: false,
          showHistory: null,
          enableAlpha: false,
        },
        model: {
          value: _vm.color,
          callback: function ($$v) {
            _vm.color = $$v
          },
          expression: "color",
        },
      }),
      _c("div", [
        _c("p", { staticClass: "colorPicker__textColor" }, [
          _vm._v(_vm._s(_vm.color)),
        ]),
        _c("p", { staticClass: "colorPicker__textDescription" }, [
          _vm._v(_vm._s(_vm.displayText)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }