/* eslint-disable */
import './add-to-cart-o'
import './alert-off'
import './alert-on'
import './american'
import './arrow-down'
import './arrow-left'
import './arrow-right'
import './camera'
import './cart-empty'
import './cart'
import './chart-down'
import './chart-graph'
import './chart-new'
import './chart-same'
import './chart-up'
import './check'
import './checkbox-custom'
import './close'
import './cloud-download'
import './copyright'
import './credit-card-o'
import './credit-card'
import './deal-o'
import './deal'
import './diners'
import './discover'
import './document-o'
import './download-o'
import './download'
import './email-help-o'
import './error'
import './eye'
import './facebook'
import './google'
import './hamburger'
import './heart-o'
import './heart'
import './info'
import './instagram'
import './jcb'
import './license'
import './lock'
import './logo-icon'
import './logo'
import './master'
import './microphone-o'
import './mp3'
import './negotiation'
import './next'
import './open-menu'
import './paper-plane'
import './pause-full'
import './pause-o'
import './pause'
import './paypal'
import './piano-o'
import './play-full'
import './play-o'
import './play'
import './prev'
import './profile-star'
import './profile'
import './search'
import './ServiceAlbum'
import './ServiceAlbums'
import './ServiceCamera'
import './ServiceCd'
import './ServiceGuitar1'
import './ServiceGuitar2'
import './ServiceHandMoney'
import './ServiceHandshake'
import './ServiceHeadphones'
import './ServiceMicrophone1'
import './ServiceMicrophone2'
import './ServiceMicrophone3'
import './ServiceMixing1'
import './ServiceMixing2'
import './ServiceMixing3'
import './ServiceMixing4'
import './ServiceMixing5'
import './ServiceMusicFile'
import './ServiceMusicFolder'
import './ServiceNote'
import './ServicePencil1'
import './ServicePencil2'
import './ServicePencil3'
import './ServicePicture'
import './ServiceRadio'
import './ServiceSpeakers'
import './settings'
import './share'
import './shopping-cart-o'
import './shopping-cart'
import './soundcloud'
import './speaker'
import './star'
import './stems'
import './success'
import './timer'
import './twitter'
import './user-alt'
import './visa'
import './wav'
import './youtube'
