var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebarContainer" }, [
    _c(
      "a",
      {
        staticClass: "sidebarOpenBtn",
        on: {
          click: function ($event) {
            _vm.isSidebarOpen = true
          },
        },
      },
      [
        _c("icon", { attrs: { name: "settings" } }),
        _c("p", [_vm._v("Customize page")]),
      ],
      1
    ),
    _vm.isSidebarOpen
      ? _c("div", { staticClass: "sidebar" }, [
          _c(
            "a",
            {
              staticClass: "sidebarCloseBtn",
              on: {
                click: function ($event) {
                  _vm.isSidebarOpen = false
                },
              },
            },
            [_c("icon", { attrs: { name: "close" } })],
            1
          ),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("colors")
                  },
                },
              },
              [_vm._v("Colors")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "colors",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_body_background",
                        displayText: "Body Background color",
                        parentState: _vm.ColorPickerParentStateType.ProductPage,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_body_text",
                        displayText: "Body Text color",
                        parentState: _vm.ColorPickerParentStateType.ProductPage,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_primary_button_bg",
                        displayText: "Primary button background color",
                        parentState: _vm.ColorPickerParentStateType.ProductPage,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "color_primary_button_text",
                        displayText: "Primary button text color",
                        parentState: _vm.ColorPickerParentStateType.ProductPage,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("header_bg")
                  },
                },
              },
              [_vm._v("Header Background")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open sidebar__content--open--xl":
                    _vm.openSection === "header_bg",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar__item" },
                  [
                    _c("PColorPicker", {
                      attrs: {
                        colorKey: "header_bg_color",
                        displayText: "Background color",
                        parentState: _vm.ColorPickerParentStateType.ProductPage,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Dim Background Image")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.header_dim_amount,
                        expression: "productPage.header_dim_amount",
                      },
                    ],
                    staticClass: "formInput__slider",
                    attrs: { type: "range", min: "0", max: "100" },
                    domProps: { value: _vm.productPage.header_dim_amount },
                    on: {
                      __r: function ($event) {
                        return _vm.$set(
                          _vm.productPage,
                          "header_dim_amount",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Background Image")]),
                  _c(
                    "div",
                    {
                      staticClass: "formUpload",
                      class: {
                        "formUpload--empty":
                          !_vm.productPage.header_bg_image_url,
                        "formUpload--uploaded":
                          _vm.productPage.header_bg_image_url,
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "formUpload__icon",
                        attrs: { name: "camera" },
                      }),
                      _vm.productPage.header_bg_image_url
                        ? _c("img", {
                            staticClass: "formUpload__image",
                            attrs: { src: _vm.productPage.header_bg_image_url },
                          })
                        : _vm._e(),
                      _c("input", {
                        staticClass: "formUpload__inputHide",
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.uploadFile(
                              $event.target.files,
                              _vm.FileToRemoveOrAdd.HeaderBackgroundImg
                            )
                          },
                        },
                      }),
                      _vm.productPage.header_bg_image_url
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "btn btn--danger btn--sm formUpload__btnDelete",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile("header_bg_image_url")
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "formLabel",
                      staticStyle: { "white-space": "normal" },
                    },
                    [_vm._v("Recommended: 2000px x 500px (.jpg or .png)")]
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("header_content")
                  },
                },
              },
              [_vm._v("Header Content")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open sidebar__content--open--xl":
                    _vm.openSection === "header_content",
                },
              },
              [
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Title")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.header_content_title,
                        expression: "productPage.header_content_title",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { type: "text", placeholder: "Header Text" },
                    domProps: { value: _vm.productPage.header_content_title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.productPage,
                          "header_content_title",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Subtitle")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.header_content_subtitle,
                        expression: "productPage.header_content_subtitle",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: {
                      rows: "5",
                      type: "text",
                      placeholder: "Paragraph",
                    },
                    domProps: {
                      value: _vm.productPage.header_content_subtitle,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.productPage,
                          "header_content_subtitle",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Content Image")]),
                  _c(
                    "div",
                    {
                      staticClass: "formUpload",
                      class: {
                        "formUpload--empty":
                          !_vm.productPage.header_content_image_url,
                        "formUpload--uploaded":
                          _vm.productPage.header_content_image_url,
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "formUpload__icon",
                        attrs: { name: "camera" },
                      }),
                      _vm.productPage.header_content_image_url
                        ? _c("img", {
                            staticClass: "formUpload__image",
                            attrs: {
                              src: _vm.productPage.header_content_image_url,
                            },
                          })
                        : _vm._e(),
                      _c("input", {
                        staticClass: "formUpload__inputHide",
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.uploadFile(
                              $event.target.files,
                              _vm.FileToRemoveOrAdd.HeaderContentImg
                            )
                          },
                        },
                      }),
                      _vm.productPage.header_content_image_url
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "btn btn--danger btn--sm formUpload__btnDelete",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile(
                                    "header_content_image_url"
                                  )
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "formLabel",
                      staticStyle: { "white-space": "normal" },
                    },
                    [_vm._v("Recommended: 500px x 500px (.jpg or .png)")]
                  ),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", { staticClass: "h-mb-10" }, [
                    _vm._v("Image Position"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.header_content_image_position,
                        expression: "productPage.header_content_image_position",
                      },
                    ],
                    staticClass: "formRadio",
                    attrs: { type: "radio", id: "header_left", value: "left" },
                    domProps: {
                      checked: _vm._q(
                        _vm.productPage.header_content_image_position,
                        "left"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.productPage,
                          "header_content_image_position",
                          "left"
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "header_left" } }, [
                    _vm._v("Left"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.header_content_image_position,
                        expression: "productPage.header_content_image_position",
                      },
                    ],
                    staticClass: "formRadio",
                    attrs: {
                      type: "radio",
                      id: "header_right",
                      value: "right",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.productPage.header_content_image_position,
                        "right"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.productPage,
                          "header_content_image_position",
                          "right"
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "header_right" } }, [
                    _vm._v("Right"),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("textContent1")
                  },
                },
              },
              [_vm._v("Text Content 1")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "textContent1",
                },
              },
              [
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Title")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.text_title1,
                        expression: "productPage.text_title1",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { type: "text", placeholder: "Title" },
                    domProps: { value: _vm.productPage.text_title1 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.productPage,
                          "text_title1",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("First Paragraph")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.text_content1,
                        expression: "productPage.text_content1",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: {
                      rows: "5",
                      type: "text",
                      placeholder: "Paragraph",
                    },
                    domProps: { value: _vm.productPage.text_content1 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.productPage,
                          "text_content1",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("youtube_image")
                  },
                },
              },
              [_vm._v("Video or Image")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "youtube_image",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flexbox sidebar__item" },
                  [
                    _c("p", [_vm._v("Media Content")]),
                    _c("toggle-button", {
                      staticClass: "vue-js-switch--margin",
                      attrs: {
                        value: _vm.productPage.media_content_is_video,
                        color: "black",
                        labels: { checked: "Video", unchecked: "Image" },
                        width: 64,
                      },
                      on: { change: _vm.toggleMediaContentType },
                    }),
                  ],
                  1
                ),
                _vm.productPage.media_content_is_video
                  ? _c("div", { staticClass: "sidebar__item" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.youtubeVideoLink,
                            expression: "youtubeVideoLink",
                          },
                        ],
                        staticClass: "formInput formInput--fullWidth",
                        attrs: {
                          type: "text",
                          placeholder: "YouTube Video Link",
                        },
                        domProps: { value: _vm.youtubeVideoLink },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.youtubeVideoLink = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _c("div", { staticClass: "sidebar__item" }, [
                      _c("p", [_vm._v("Content Image")]),
                      _c(
                        "div",
                        {
                          staticClass: "formUpload",
                          class: {
                            "formUpload--empty": !_vm.productPage.image_url,
                            "formUpload--uploaded": _vm.productPage.image_url,
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "formUpload__icon",
                            attrs: { name: "camera" },
                          }),
                          _vm.productPage.image_url
                            ? _c("img", {
                                staticClass: "formUpload__image",
                                attrs: { src: _vm.productPage.image_url },
                              })
                            : _vm._e(),
                          _c("input", {
                            staticClass: "formUpload__inputHide",
                            attrs: { type: "file" },
                            on: {
                              change: function ($event) {
                                return _vm.uploadFile(
                                  $event.target.files,
                                  _vm.FileToRemoveOrAdd.Image
                                )
                              },
                            },
                          }),
                          _vm.productPage.image_url
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn--danger btn--sm formUpload__btnDelete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFile("image_url")
                                    },
                                  },
                                },
                                [_vm._v("Remove")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "formLabel",
                          staticStyle: { "white-space": "normal" },
                        },
                        [_vm._v("Recommended: 500px x 500px (.jpg or .png) ")]
                      ),
                    ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", { staticClass: "h-mb-10" }, [
                    _vm._v("Image Position"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.media_content_position,
                        expression: "productPage.media_content_position",
                      },
                    ],
                    staticClass: "formRadio",
                    attrs: { type: "radio", id: "main_left", value: "left" },
                    domProps: {
                      checked: _vm._q(
                        _vm.productPage.media_content_position,
                        "left"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.productPage,
                          "media_content_position",
                          "left"
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "main_left" } }, [
                    _vm._v("Left"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.media_content_position,
                        expression: "productPage.media_content_position",
                      },
                    ],
                    staticClass: "formRadio",
                    attrs: {
                      type: "radio",
                      id: "main_center",
                      value: "center",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.productPage.media_content_position,
                        "center"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.productPage,
                          "media_content_position",
                          "center"
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "main_center" } }, [
                    _vm._v("Center"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.media_content_position,
                        expression: "productPage.media_content_position",
                      },
                    ],
                    staticClass: "formRadio",
                    attrs: { type: "radio", id: "main_right", value: "right" },
                    domProps: {
                      checked: _vm._q(
                        _vm.productPage.media_content_position,
                        "right"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.productPage,
                          "media_content_position",
                          "right"
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "main_right" } }, [
                    _vm._v("Right"),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "sidebar__section" }, [
            _c(
              "h3",
              {
                staticClass: "sidebar__title",
                on: {
                  click: function ($event) {
                    return _vm.openCloseSection("textContent2")
                  },
                },
              },
              [_vm._v("Text Content 2")]
            ),
            _c(
              "div",
              {
                staticClass: "sidebar__content",
                class: {
                  "sidebar__content--open": _vm.openSection === "textContent2",
                },
              },
              [
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Title")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.text_title2,
                        expression: "productPage.text_title2",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { type: "text", placeholder: "Title" },
                    domProps: { value: _vm.productPage.text_title2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.productPage,
                          "text_title2",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "sidebar__item" }, [
                  _c("p", [_vm._v("Second Paragraph")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productPage.text_content2,
                        expression: "productPage.text_content2",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: {
                      rows: "5",
                      type: "text",
                      placeholder: "Paragraph",
                    },
                    domProps: { value: _vm.productPage.text_content2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.productPage,
                          "text_content2",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "flexbox flexbox--center h-mt-40" }, [
            _c(
              "button",
              { staticClass: "btn btn--black", on: { click: _vm.saveChanges } },
              [_vm._v("Save Changes")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }